import React, { useContext, useEffect, useMemo, useState } from "react"
import tw from "twin.macro"
import { InventoryClient } from "../../../clients/InventoryClient"
import {
  removeFavoritedDealer,
  addFavoritedDealer,
  setFavoriteMessage,
} from "../../../contexts/Favorites/actions"
import { ButtonLink, Button } from "../../atoms/Button"
import Icon from "../../atoms/Icon"
import { Link } from "../../atoms/Link"
import { DealerProps } from "./Dealer.d"
import { FavoritesContext } from "../../../contexts/Favorites/context"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { ContactContext } from "../../../contexts/Contact"
import {
  openContactDealerModal,
  openTactContactDealerModal,
  updateField,
} from "../../../contexts/Contact/actions"
import { LanguageContext } from "../../../contexts/Language"
import { useTealiumContext } from "../../../contexts/Tealium"
import { LocationContext } from "../../../contexts/Location"
import { useLocation } from "@reach/router"
import { generateInventoryLink, slugify } from "../../../helpers"
import moment from "moment"

type HoursObject = Record<
  string,
  {
    DayOfWeek: string
    OpeningTime: string
    ClosingTime: string
  }
>

const Dealer: React.FC<DealerProps> = ({
  i,
  dealer,
  collapsable,
  excludeCallDealerPages = [],
  onClick = null,
  selected = false,
  dealerCtaText,
  dealerCtaLink,
  lmAnalyticsId = null,
  vdwAnalyticsId = null,
  ctcAnalyticsId = null,
  gdAnalyticsId = null,
  ssrvcAnalyticsId = null,
  cdAnalyticsId = null,
  ctaAnalyticsId = null,
  offerType = null,
  carCareTopic = null,
  leadDetails = {
    vehicle: null,
    leadType: null,
    contactButtonType: "ContactDealer",
    confirmContactDealerSelection: false,
    comment: null,
  },
}): JSX.Element => {
  const [{ zip }] = useContext(LocationContext)
  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  const { tealPageData, lifestyleLanderTealData } = useTealiumContext()
  const [dealerInventory, setDealerInventory] = useState<number>(0)
  const [{ favoritedDealer }, dispatch] = useContext(FavoritesContext)
  const [_contactState, contactDispatch] = useContext(ContactContext)
  const { _, language } = useContext(LanguageContext)

  const isFavorite =
    favoritedDealer && favoritedDealer.DealerCode === dealer.DealerCode

  const hoursOfOperation = useMemo(() => {
    const now = new Date()
    const isBetween = (
      hrs: HoursObject,
      type: "MainDealer" | "Sales" | "Service",
    ) => {
      const currentTime = moment(now.toLocaleTimeString("en-US"), "h:mm:ss A")
      const start = moment(hrs[type]?.OpeningTime, "h:mm:ss A")
      const end = moment(hrs[type]?.ClosingTime, "h:mm:ss A")
      return currentTime.isBetween(start, end)
    }
    const listOfDays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ]
    // Get the current day of the week
    let hours: HoursObject = {}
    const departmentTypes = ["Main Dealer", "Service", "Sales"]
    departmentTypes.forEach(departmentType => {
      hours[departmentType.replace(" ", "")] = dealer?.Departments?.find(
        dept => dept.name === departmentType,
      )?.hoursOfOperation?.find(
        day => day.DayOfWeek === listOfDays[now.getDay()],
      ) || { DayOfWeek: "", OpeningTime: "", ClosingTime: "" }
    })

    switch (true) {
      case isBetween(hours, "MainDealer"):
        return hours["MainDealer"]
      case isBetween(hours, "Sales"):
        return hours["Sales"]
      case isBetween(hours, "Service"):
        return hours["Service"]
      default:
        return null
    }
  }, [dealer])

  let ctaLinkToUse = ""
  if (dealerCtaText) {
    switch (dealerCtaLink) {
      case "tact":
        ctaLinkToUse = dealer.TactUrl
        break
      case "tires":
        ctaLinkToUse = dealer.DealerTireUrl
        //FOR Dealer Tire, we need to use the value from DIS first,
        //in the instance a dealer doesn't have a dealer tire url specificed in DIS we should fall back to our dealers table
        //this `DealerTireUrl` in our API accounts for that logic already
        break
      case "scheduleService":
        ctaLinkToUse = dealer.ServiceUrl
        break
      case "parts":
        const englishTrackingCode =
          "?siteid=explore_tpco_exploresite_shopnow_GM"
        const spanishTrackingCode =
          "?siteid=explore_tpco_exploresite_shopnow_HM"

        ctaLinkToUse = dealer.PartsUrl.includes("autoparts")
          ? dealer.PartsUrl +
            (language === "en" ? englishTrackingCode : spanishTrackingCode)
          : dealer.PartsUrl
        break
      default:
        ctaLinkToUse = dealer.Url
    }
  }

  useEffect(() => {
    const setInventoryCount = async () => {
      const { count } = await InventoryClient.get({
        dealer: dealer.DealerCode,
        countOnly: true,
      })
      setDealerInventory(count)
    }
    setInventoryCount()
  }, [])

  function formatPhoneNumber(phoneNumber: string) {
    const cleaned = phoneNumber.replace(/\D/g, "")
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`
    }
    return phoneNumber
  }
  const location = useLocation()
  const currentPage = location.pathname
  const shouldExclude = excludeCallDealerPages.includes(currentPage.trim())

  // Parameters:  Name of event and an object with additional properties for tealium event call if required
  const handleTealEvent: (eventName: string, moreData?: {}) => void = (
    eventName = "No event name provided",
    moreData = {},
  ) => {
    trackTealEvent({
      tealium_event: eventName,
      dealer_name: dealer?.Name,
      dealer_code: dealer?.DealerCode,
      page_type: tealPageData.page_type,
      customer_zip: zip,
      ...(offerType && { offer_type: offerType }),
      ...(tealPageData.page_type == "lifestyle" && {
        vehicle_category: lifestyleLanderTealData.selectedCategories,
      }),
      ...(carCareTopic && { vehicle_category: [carCareTopic] }),
      ...moreData,
    })
  }

  const inventoryLinkOptions = {
    dealer: dealer.DealerCode,
  }

  /**
   *  Windows users were seeing blank page when clicking on phone number link.
   *  If detected user is on a Windows device, we will display the phone number as text.
   */
  const isWindows =
    typeof window !== "undefined" ? navigator.userAgent.includes("Win") : false

  return (
    <div css={[tw`w-full`]}>
      <div css={[tw`flex justify-between items-start`]}>
        <button
          css={[
            tw`flex text-lg font-semibold text-left items-center`,
            tw`focus-visible:outline-gray-50`,
          ]}
          onClick={onClick || undefined}
        >
          <span css={[tw`pr-1.5`]}>{i && `${i}. `}</span>
          <span css={[tw`pr-2`]}>
            {/* OPTIMIZELY EXPERIMENT #9 DEALER NAME CONTROL */}
            <div className="optimizelyDealerExpNineControl">{dealer.Name}</div>
            {/* OPTIMIZELY EXPERIMENT #9 DEALER DETAILS VARIANT */}
            <Link
              to={`/dealers/${slugify(dealer?.Name)}`}
              target="_blank"
              css={[tw`p-0 my-1 hidden`, tw`focus-visible:outline-gray-50`]}
              animated
              animatedThin
              aria-label={_("learn more about") + " " + dealer.Name}
              onClick={() => {
                handleTealEvent("dealer_detail_click")
              }}
              analytics-id={lmAnalyticsId || (i && `learn more:dealers:${i}`)}
              className="optimizelyDealerExpNineVariant"
            >
              {dealer.Name}
              <Icon.Chevron
                css={[tw`h-2.5 inline-flex ml-1 justify-center text-red-400`]}
              />
            </Link>
          </span>
        </button>
        <button
          onClick={() => {
            if (isFavorite) {
              dispatch(removeFavoritedDealer())
            } else {
              dispatch(addFavoritedDealer(dealer))
              dispatch(setFavoriteMessage("dealer"))
            }
          }}
          css={[
            tw`flex items-center justify-center`,
            tw`focus-visible:outline-gray-50`,
          ]}
          aria-label={
            isFavorite ? `Unfavorite ${dealer.Name}` : `Favorite ${dealer.Name}`
          }
        >
          <Icon.Heart
            color={isFavorite ? "red-400" : "gray-900"}
            css={[tw`h-8 -mb-1`]}
            filled={isFavorite}
          />
        </button>
      </div>
      <div
        css={[
          tw`hidden overflow-hidden grid-cols-2 grid-rows-1 gap-4 grid-flow-row w-auto h-auto`,
          (!collapsable || selected) && tw`grid`,
        ]}
      >
        <div>
          {/* column 1 */}
          <div css={[tw`grid grid-cols-12 mt-3 gap-x-4 md:(gap-x-2)`]}>
            <div css={[tw`col-span-12 ml-[22px]`]}>
              <span css={[tw`block text-sm font-semibold`]}>
                {dealer.Distance} {_("mi away")}{" "}
              </span>
              <Link
                to={`https://www.google.com/maps/search/?api=1&query=${dealer.Name} ${dealer.Address1} ${dealer.City}, ${dealer.State} ${dealer.Zip}`}
                css={[
                  tw`text-red-300 p-0 my-1 mr-3`,
                  tw`focus-visible:outline-gray-50`,
                ]}
                animated
                animatedThin
                onClick={() => handleTealEvent("get_directions")}
                analytics-id={gdAnalyticsId || (i && `directions:dealers:${i}`)}
              >
                <div css={[tw`text-sm font-semibold`]}>{dealer.Address1}</div>
                <div css={[tw`text-sm font-semibold`]}>
                  {dealer.City}, {dealer.State} {dealer.Zip}
                </div>
              </Link>
            </div>

            <div css={[tw`col-span-12 ml-[22px] pt-2 text-black`]}>
              <span css={[tw`font-semibold text-sm block`]}>
                {_("Hours Today")}:
              </span>
              <span css={[tw`block my-1 uppercase text-sm`]}>
                {hoursOfOperation
                  ? `${hoursOfOperation?.OpeningTime} - ${hoursOfOperation?.ClosingTime}`
                  : _("Closed Now", true)}
              </span>
            </div>
          </div>
        </div>
        {/* column 2 */}
        <div css={[tw`text-xs grid grid-cols-12 mt-3 gap-x-2 md:(gap-x-2)`]}>
          <div css={[tw`col-span-12 text-sm font-semibold ml-1`]}>
            {isWindows ? (
              <div css={tw`my-2 p-0`}>{formatPhoneNumber(dealer.Phone)}</div>
            ) : (
              <Link
                to={`tel:${dealer.Phone}`}
                css={[
                  tw`my-2 p-0`,
                  tw`focus-visible:outline-gray-50 disabled:text-black`,
                ]}
                animated
                animatedThin
                onClick={() => handleTealEvent("click_to_call")}
                analytics-id={ctcAnalyticsId || (i && `call:dealers:${i}`)}
              >
                {formatPhoneNumber(dealer.Phone)}
                <Icon.Chevron
                  css={[tw`h-2.5 inline-flex ml-1 justify-center text-red-400`]}
                />
              </Link>
            )}
          </div>
          <div css={[tw`col-span-12 ml-1`]}>
            <Link
              to={dealer.ServiceUrl}
              target="_blank"
              css={[
                tw`text-sm font-semibold p-0 my-1`,
                tw`focus-visible:outline-gray-50`,
              ]}
              animated
              animatedThin
              onClick={() => handleTealEvent("schedule_service", {})}
              analytics-id={
                ssrvcAnalyticsId || (i && `schedule service:dealers:${i}`)
              }
            >
              {_("Schedule Service")}
              <Icon.Chevron
                css={[tw`h-2.5 inline-flex ml-1 justify-center text-red-400`]}
              />
            </Link>
          </div>
          <div css={[tw`col-span-12 font-semibold ml-1`]}>
            {/* OPTIMIZELY EXPERIMENT #9 LEARN MORE LINK CONTROL */}
            <Link
              to={`/dealers/${slugify(dealer?.Name)}`}
              target="_blank"
              css={[tw`text-sm p-0 my-1`, tw`focus-visible:outline-gray-50`]}
              animated
              animatedThin
              aria-label={_("learn more")}
              onClick={() => {
                handleTealEvent("dealer_detail_click")
              }}
              analytics-id={lmAnalyticsId || (i && `learn more:dealers:${i}`)}
              className="optimizelyDealerExpNineControl"
            >
              {_("Learn More")}{" "}
              <Icon.Chevron
                css={[tw`h-2.5 inline-flex ml-1 justify-center text-red-400`]}
              />
            </Link>
            {/* OPTIMIZELY EXPERIMENT #9 VIEW INVENTORY LINK VARIANT */}
            <Link
              to={generateInventoryLink(inventoryLinkOptions)}
              target="_blank"
              css={[
                tw`text-sm p-0 my-1 hidden`,
                tw`focus-visible:outline-gray-50`,
              ]}
              animated
              animatedThin
              aria-label={_("view inventory") + " " + dealer.Name}
              onClick={() => {
                handleTealEvent("view_inventory_click")
              }}
              analytics-id={lmAnalyticsId || (i && `learn more:dealers:${i}`)}
              className="optimizelyDealerExpNineVariant optimizelyDealerExpNinePrimaryMetric"
            >
              {_("View Inventory")}{" "}
              <span css={[tw`text-red-300`]}>({dealerInventory})</span>
              <Icon.Chevron
                css={[tw`h-2.5 inline-flex ml-1 justify-center text-red-400`]}
              />
            </Link>
          </div>
        </div>

        {dealerCtaLink &&
        dealerCtaLink !== "contact" &&
        dealerCtaLink !== "tact" ? (
          <ButtonLink
            to={ctaLinkToUse}
            target="_blank"
            primary
            css={[tw`text-xs px-2 my-2 flex items-center justify-center`]}
            onClick={() => {
              let eventName = "contact_dealer_rest"
              if (ctaAnalyticsId) {
                if (ctaAnalyticsId == "shop tires") {
                  eventName = "shop_tires"
                } else if (ctaAnalyticsId == "schedule service") {
                  eventName = "schedule_service"
                } else if (ctaAnalyticsId == "shop now") {
                  eventName = "parts_ext_link"
                } else if (ctaAnalyticsId == "apply now") {
                  eventName = "tact_apply"
                }
              }
              if (ctaAnalyticsId == "apply now") {
                handleTealEvent(eventName, { coupon_module_text: "Apply Now" })
              } else {
                handleTealEvent(eventName)
              }
            }}
            analytics-id={
              ctaAnalyticsId && i
                ? `${ctaAnalyticsId}:dealers:${i}`
                : `${dealerCtaText}:top nav:favorite dealer`
            }
            className={
              ctaAnalyticsId == "shop now" ||
              ctaAnalyticsId == "schedule service" ||
              ctaAnalyticsId == "shop tires"
                ? "optimizelyShopAndScheduleDNYClicks optimizelyTest17VariantCTA"
                : undefined
            }
          >
            {dealerCtaText}
          </ButtonLink>
        ) : (
          <Button
            primary
            css={[tw`text-xs px-0! m-2 flex items-center justify-center`]}
            onClick={() => {
              handleTealEvent(
                "contact_dealer_rest",
                dealerCtaLink === "tact"
                  ? { coupon_module_text: dealerCtaText }
                  : "",
              )
              dealerCtaLink === "tact"
                ? contactDispatch(openTactContactDealerModal(dealer))
                : contactDispatch(
                    openContactDealerModal(
                      dealer,
                      leadDetails?.vehicle,
                      leadDetails?.leadType,
                      leadDetails?.contactButtonType,
                      leadDetails?.confirmContactDealerSelection,
                    ),
                  )
              leadDetails?.comment &&
                contactDispatch(updateField("comments", leadDetails.comment))
            }}
            analytics-id={cdAnalyticsId || (i && `contact:dealers:${i}`)}
          >
            {dealerCtaText ? dealerCtaText : _("Contact Dealer")}
          </Button>
        )}
        <ButtonLink
          to={`${dealer.MainDealerUrl}`}
          secondary
          css={[
            tw`text-xs px-0! m-2 flex items-center justify-center`,
            tw`focus-visible:outline-gray-50!`,
          ]}
          aria-label={_("visit dealer website") + " " + dealer.Name}
          onClick={() => handleTealEvent("visit_dealer_website")}
          analytics-id={vdwAnalyticsId || (i && `visit website:dealers:${i}`)}
          className="optimizelyTest17ControlVisitWebsite"
        >
          {_("Visit Website")}
        </ButtonLink>
      </div>
      {!shouldExclude && (
        <ButtonLink
          to={`tel:${dealer.Phone}`}
          primary
          css={[
            tw`text-xs p-1.5! m-2 flex items-center justify-center`,
            tw`focus-visible:outline-gray-50!`,
            tw`md:(hidden)`,
          ]}
          aria-label={_("call dealer") + " " + dealer.Name}
          onClick={() => {
            trackTealEvent({
              tealium_event: "click_to_call",
              dealer_name: dealer?.Name,
              dealer_code: dealer?.DealerCode,
              coupon_module_text: "Call Dealer",
              page_type: tealPageData.page_type,
              customer_zip: zip,
              ...(offerType && { offer_type: offerType }),
              ...(carCareTopic && { vehicle_category: [carCareTopic] }),
            })
          }}
          analytics-id={ctcAnalyticsId || (i && `call:dealers:${i}`)}
          className="optimizelyTest17ControlVisitWebsite"
        >
          <Icon.PhoneRinging css={[tw`h-8`]} />
          {_("Call Dealer")}
        </ButtonLink>
      )}
    </div>
  )
}

export default Dealer
